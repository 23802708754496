<template>
  <div>
    <Header class="d-lg-block d-none" />
    <HeaderBack class="d-lg-none d-block" />
    <CategoryList />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import HeaderBack from "@/components/HeaderBack.vue";
import CategoryList from "@/components/CategoryList.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Category",
  components: {
    Header,
    HeaderBack,
    CategoryList,
    Footer,
  },
  created() {
    this.$mixpanel.track("Visit Category Page");
  },
};
</script>

<style>
</style>