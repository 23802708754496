var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container px-lg-4 px-0 d-block d-lg-none"},[_c('section',{staticClass:"unSwiper-cards margin-t-20"},[_vm._m(0),_c('div',{staticClass:"discover-nft-random margin-t-20"},[_c('div',{staticClass:"content-NFTs-body"},_vm._l((_vm.categoryList),function(category,index){return _c('div',{key:index,staticClass:"item-sm-card-NFTs"},[_c('router-link',{attrs:{"to":'/category-choose/' + category.slug}},[_c('div',{staticClass:"cover-image"},[_c('picture',[_c('source',{staticClass:"big-image",attrs:{"srcset":!category.logo_url?.startsWith('/images')
                        ? 'https://api-dapps.dinowallet.org/images/category/' +
                          category?.logo_url
                        : category?.logo_url,"type":"image/webp"}}),_c('img',{staticClass:"big-image",attrs:{"src":!category.logo_url?.startsWith('/images')
                        ? 'https://api-dapps.dinowallet.org/images/category/' +
                          category?.logo_url
                        : category?.logo_url,"alt":""}})]),_c('div',{staticClass:"content-text"},[_c('div',{staticClass:"btn-like-click"},[_c('div',{staticClass:"btnLike"},[_c('input',{attrs:{"type":"checkbox"}}),_c('i',{staticClass:"ri-apps-line me-1"}),_c('span',{staticClass:"count-likes"},[_vm._v(_vm._s(category.products_count))])])])]),_c('div',{staticClass:"user-text"},[_c('div',{staticClass:"user-avatar"},[_c('span',[_vm._v(_vm._s(category.name))])]),_c('div',{staticClass:"number-eth"},[_c('span',{staticClass:"main-price"},[_vm._v(_vm._s(category.products_count)+" Dapps Listed")])])])])])],1)}),0)])])]),_c('section',{staticClass:"py-5 bg-light unList-bestSeller d-lg-block d-none"},[_c('div',{staticClass:"unList-creatores collection-creatores bg-light container py-3 d-lg-block d-none"},[_c('div',{staticClass:"content-list-creatores"},[_vm._m(1),_c('ul',{staticClass:"nav row col-12 align-items-center w-100 m-0"},_vm._l((_vm.categoryList),function(category,index){return _c('li',{key:index,staticClass:"nav-item col-4"},[_c('div',{staticClass:"nav-link visited",staticStyle:{"padding":"20px 0px !important"}},[_c('router-link',{staticClass:"d-flex align-items-center bg-white justify-content-between border border-gray shadow-sm p-3 rounded-10 text-decoration-none",attrs:{"to":'/category-choose/' + category.slug}},[_c('div',{staticClass:"item-user-img"},[_c('div',{staticClass:"wrapper-image"},[_c('picture',[_c('source',{attrs:{"srcset":!category.logo_url?.startsWith('/images')
                            ? 'https://api-dapps.dinowallet.org/images/category/' +
                              category?.logo_url
                            : category?.logo_url,"type":"image/webp"}}),_c('img',{staticClass:"avt-img",attrs:{"src":!category.logo_url?.startsWith('/images')
                            ? 'https://api-dapps.dinowallet.org/images/category/' +
                              category?.logo_url
                            : category?.logo_url,"alt":""}})]),_c('div',{staticClass:"icon"},[_c('i',{staticClass:"ri-checkbox-circle-fill"})])]),_c('div',{staticClass:"txt-user"},[_c('h5',[_vm._v(_vm._s(category.name))]),_c('p',[_vm._v("Click to see all dapps")])])]),_c('div',{staticClass:"other-option"},[_c('div',{staticClass:"color-text rounded-pill bg-snow btn-xs-size"},[_vm._v(" "+_vm._s(category.products_count)+" Dapps ")])])])],1)])}),0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"un-title-default"},[_c('div',{staticClass:"text"},[_c('h2',[_vm._v("All Dapps Category")]),_c('p',[_vm._v("Choose and find your dApps")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[_c('h2',{staticClass:"title-gradient fw-bold"},[_vm._v("All Dapps Category")]),_c('p',[_vm._v("Choose and find your dApps")])])])
}]

export { render, staticRenderFns }