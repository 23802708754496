<template>
  <div>
    <div class="container px-lg-4 px-0 d-block d-lg-none">
      <section class="unSwiper-cards margin-t-20">
        <!-- un-title-default -->
        <div class="un-title-default">
          <div class="text">
            <h2>All Dapps Category</h2>
            <p>Choose and find your dApps</p>
          </div>
        </div>
        <div class="discover-nft-random margin-t-20">
          <div class="content-NFTs-body">
            <div
              class="item-sm-card-NFTs"
              v-for="(category, index) in categoryList"
              :key="index"
            >
              <router-link :to="'/category-choose/' + category.slug">
                <div class="cover-image">
                  <picture>
                    <source
                      :srcset="
                        !category.logo_url?.startsWith('/images')
                          ? 'https://api-dapps.dinowallet.org/images/category/' +
                            category?.logo_url
                          : category?.logo_url
                      "
                      class="big-image"
                      type="image/webp"
                    />
                    <img
                      class="big-image"
                      :src="
                        !category.logo_url?.startsWith('/images')
                          ? 'https://api-dapps.dinowallet.org/images/category/' +
                            category?.logo_url
                          : category?.logo_url
                      "
                      alt=""
                    />
                  </picture>
                  <div class="content-text">
                    <div class="btn-like-click">
                      <div class="btnLike">
                        <input type="checkbox" />
                        <i class="ri-apps-line me-1"></i>
                        <span class="count-likes">{{
                          category.products_count
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="user-text">
                    <div class="user-avatar">
                      <span>{{ category.name }}</span>
                    </div>
                    <div class="number-eth">
                      <span class="main-price"
                        >{{ category.products_count }} Dapps Listed</span
                      >
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </section>
    </div>

    <section class="py-5 bg-light unList-bestSeller d-lg-block d-none">
      <div
        class="
          unList-creatores
          collection-creatores
          bg-light
          container
          py-3
          d-lg-block d-none
        "
      >
        <div class="content-list-creatores">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h2 class="title-gradient fw-bold">All Dapps Category</h2>
              <p>Choose and find your dApps</p>
            </div>
          </div>
          <ul class="nav row col-12 align-items-center w-100 m-0">
            <li
              class="nav-item col-4"
              v-for="(category, index) in categoryList"
              :key="index"
            >
              <div
                class="nav-link visited"
                style="padding: 20px 0px !important"
              >
                <router-link
                  :to="'/category-choose/' + category.slug"
                  class="
                    d-flex
                    align-items-center
                    bg-white
                    justify-content-between
                    border border-gray
                    shadow-sm
                    p-3
                    rounded-10
                    text-decoration-none
                  "
                >
                  <div class="item-user-img">
                    <div class="wrapper-image">
                      <picture>
                        <source
                          :srcset="
                            !category.logo_url?.startsWith('/images')
                              ? 'https://api-dapps.dinowallet.org/images/category/' +
                                category?.logo_url
                              : category?.logo_url
                          "
                          type="image/webp"
                        />
                        <img
                          class="avt-img"
                          :src="
                            !category.logo_url?.startsWith('/images')
                              ? 'https://api-dapps.dinowallet.org/images/category/' +
                                category?.logo_url
                              : category?.logo_url
                          "
                          alt=""
                        />
                      </picture>
                      <div class="icon">
                        <i class="ri-checkbox-circle-fill"></i>
                      </div>
                    </div>
                    <div class="txt-user">
                      <h5>{{ category.name }}</h5>
                      <p>Click to see all dapps</p>
                    </div>
                  </div>
                  <div class="other-option">
                    <div class="color-text rounded-pill bg-snow btn-xs-size">
                      {{ category.products_count }} Dapps
                    </div>
                  </div>
                </router-link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CategoryList",
  data() {
    return {
      categoryList: [],
    };
  },
  mounted() {
    this.getCategory();
  },
  methods: {
    async getCategory() {
      try {
        await axios
          .get(process.env.VUE_APP_API + "api/category/get-category")
          .then((response) => (this.categoryList = response.data.data));
      } catch (e) {
        console.log("Caught Error");
      }
    },
  },
};
</script>

<style>
@media screen and (min-width: 769px) {
  .discover-nft-random .content-NFTs-body {
    padding: 0 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }
  .item-sm-card-NFTs .cover-image .big-image {
    height: 400px;
  }
}
</style>
